import AP_pdf from "../../../../contents/index/AP.pdf";
import enpit_pdf from "../../../../contents/index/enpit.pdf";
import wiss_pdf from "../../../../contents/index/wiss.pdf";
import gakurui_cho_award from "../../../../contents/index/gakurui-cho-award.pdf";
import hci210 from "../../../../contents/index/HCI210\u5B66\u751F\u5968\u52B1\u8CDE.pdf";
import * as React from 'react';
export default {
  AP_pdf,
  enpit_pdf,
  wiss_pdf,
  gakurui_cho_award,
  hci210,
  React
};